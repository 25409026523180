
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useMainStore = defineStore(
    'raffle', ()=> {
        const currentIndex = ref(-1);
        const currentRaffle=ref({id:-1,name:"",draw:'',winningNumber:'',from:'',to:'',numbers:[],results:[],keepAwake:true});
        const raffles = ref([]);

        return {
            currentIndex,
            currentRaffle,
            raffles
        }
    }
)