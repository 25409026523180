
<template>
  <v-container>
    <!-- show blue heading bar with white text RafL.au-->
    <v-row>
      <v-col cols="12">
        <v-card color="blue" dark>
          <v-card-title>
            <span class="headline">RafL.au</span>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <div style="margin-left:10px;margin-top:10px;">
      <!--show an input box with the label raffle-->
      <v-row>
        <v-col cols="2">
          <v-btn @click="clearData">
            Clear
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="10">
          <v-text-field
            v-model="main.currentRaffle.name"
            label="Raffle"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="2">
        </v-col>
      </v-row>

      <v-divider></v-divider>
      <v-row style="margin-left:0px;margin-top:10px;">
        Raffle Ticket Numbers
      </v-row>

      <!-- create a list of from to text boxes for each item in main.currentRaffle.numbers and an empty set of from and to boxes at the end-->
      <v-row>
        <v-col cols="12">
          <v-row style="margin-top:-15px;">
            <v-col cols="5">
              <v-text-field
                v-model="main.currentRaffle.from"
                label="From"
                outlined
                type="number"
                @keydown="checkNumeric"
              ></v-text-field>
            </v-col>
            <v-col cols="5">
              <v-text-field
                v-model="main.currentRaffle.to"
                label="To"
                outlined
                type="number"
                @keydown="checkNumeric"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <div v-if="validFromTo2()">
                <v-btn icon @click="fromToChange" v-if="main.currentRaffle.from !== '' && main.currentRaffle.to !== ''">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
                <v-btn icon @click="clearFromTo" v-else>
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
              <div v-else>
                <v-btn icon @click="fromToChange" v-if="main.currentRaffle.from !== '' && main.currentRaffle.to !== ''" style="color:red;">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
                <v-btn icon @click="clearFromTo" v-else style="color:red;">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row v-for="(number, index) in main.currentRaffle.numbers" :key="index" style="margin-top:-35px;">
          <!--create a bin icon to remove this index -->
          <v-col cols="5">
              <v-text-field
                v-model="main.currentRaffle.numbers[index].from"
                label="From"
                outlined
                type="number"
                @keydown="checkNumeric"
              ></v-text-field>
            </v-col>
            <v-col cols="5">
              <v-text-field
                v-model="main.currentRaffle.numbers[index].to"
                label="To"
                outlined
                type="number"
                @keydown="checkNumeric"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-btn icon @click="main.currentRaffle.numbers.splice(index, 1)" v-if="validFromTo(index)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
              <v-btn icon @click="main.currentRaffle.numbers.splice(index, 1)" v-else style="color:red;">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- show a separator line -->
      <v-divider></v-divider>
      <v-row style="margin-left:0px;margin-top:10px;">
        Winning Numbers
      </v-row>
      <!-- create a list of draw and winning number text boxes for each item in main.currentRaffle.results and an empty draw and winning number inputs at the start -->
      <v-row>
        <v-col cols="12">
          <v-row style="margin-top:-15px;">
            <v-col cols="5">
              <v-text-field
                v-model="main.currentRaffle.draw"
                label="Draw"
                outlined
                type="number"
                @keydown="checkNumeric"
              ></v-text-field>
            </v-col>
            <v-col cols="5">
              <v-text-field
                v-model="main.currentRaffle.winningNumber"
                label="Number"
                outlined
                type="number"
                @keydown="checkNumeric"
              ></v-text-field>
            </v-col>
            <v-col cols="2" v-if="checkRange(main.currentRaffle.winningNumber)">
              <v-btn icon @click="saveResult" style="color:green;" v-if="main.currentRaffle.draw && main.currentRaffle.winningNumber && main.currentRaffle.draw !== '' && main.currentRaffle.winningNumber !== ''">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <v-btn icon @click="clearResult" style="color:green;" v-else>
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="2" v-else>
              <v-btn icon @click="saveResult" style="color:red;" v-if="main.currentRaffle.draw && main.currentRaffle.winningNumber && main.currentRaffle.draw !== '' && main.currentRaffle.winningNumber !== ''">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <v-btn icon @click="clearResult" style="color:red;" v-else>
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-for="(result, index) in main.currentRaffle.results" :key="index" style="margin-top:-25px;">
            <v-col cols="5">
              <v-text-field
                v-model="main.currentRaffle.results[index].draw"
                label="Draw"
                outlined
                type="number"
                @keydown="checkNumeric"
              ></v-text-field>
            </v-col>
            <v-col cols="5">
              <v-text-field
                v-model="main.currentRaffle.results[index].winningNumber"
                label="Number"
                outlined
                type="number"
                @keydown="checkNumeric"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-btn icon @click="main.currentRaffle.results.splice(index, 1)" style="color:green;" v-if="checkRange(main.currentRaffle.results[index].winningNumber)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
              <v-btn icon @click="main.currentRaffle.results.splice(index, 1)" style="color:red;" v-else>
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      
    </div>
  </v-container>
</template>

<script>
import { useMainStore } from "@/store/mainStore";

export default {
  async setup() {
    const main = useMainStore();

    //set the currentRaffle.name to the current date in format dd/mm/yyyy using AU format
    if (main.currentRaffle.name === "")
      main.currentRaffle.name = new Date().toLocaleDateString("en-AU");

    main.$persist();

    //if numberFrom or numberTo changes, update the main.currentRaffle.numbers array
    const fromToChange = () => {
      if (main.currentRaffle.to !== "" && main.currentRaffle.from !== "") {
        //if main.currentRaffle.number.value is not defined, create it

        main.currentRaffle.numbers.push({
          from: main.currentRaffle.from,
          to: main.currentRaffle.to,
        });
        main.$persist();
        main.currentRaffle.from = "";
        main.currentRaffle.to = "";
      }
    };

    const clearFromTo = () => {
      main.currentRaffle.from = "";
      main.currentRaffle.to = "";
    };

    const clearData = () => {
      main.currentRaffle = {
        name: "",
        from: "",
        to: "",
        numbers: [],
        draw: "",
        winningNumber: "",
        results: [],
        keepAwake:true,
      };
      if (main.currentRaffle.name === "")
        main.currentRaffle.name = new Date().toLocaleDateString("en-AU");
    };

    const saveResult = () => {
      if (main.currentRaffle.draw && main.currentRaffle.winningNumber) {
        main.currentRaffle.results.push({
          draw: main.currentRaffle.draw,
          winningNumber: main.currentRaffle.winningNumber,
        });
        main.$persist();
        main.currentRaffle.draw = "";
        main.currentRaffle.winningNumber = "";
      }
    };

    const clearResult = () => {
      main.currentRaffle.draw = "";
      main.currentRaffle.winningNumber = "";
    };

    //check if the number is in the range of any main.currentRaffle.from and main.currentRaffle.to entry return true
    const checkRange = (number) => {
      for (let i = 0; i < main.currentRaffle.numbers.length; i++) {
        if (
          Number(number) >= Number(main.currentRaffle.numbers[i].from) &&
          Number(number) <= Number(main.currentRaffle.numbers[i].to)
        ) {
          return true;
        }
      }

      //if the number is in the range of any main.currentRaffle.from and main.currentRaffle.to entry return true
      if (main.currentRaffle.from && main.currentRaffle.to && Number(number)>0 && Number(main.currentRaffle.from)>0 && Number(main.currentRaffle.to)>0 && Number(number) >= Number(main.currentRaffle.from) && Number(number) <= Number(main.currentRaffle.to))
        return true;

      return false;
    };

    const checkNumeric = (e) => {
      //check if the key pressed is a number (do not allow - + or .) or any control key such as CTRL-C, CTRL-V, CTRL-X
      if (
        (e.key >= "0" && e.key <= "9") ||
        e.ctrlKey || e.metaKey || e.key === "Tab" ||
        e.key === "ArrowLeft" ||
        e.key === "ArrowRight" ||
        e.key === "Home" ||
        e.key === "End" ||
        e.key === "Delete" ||
        e.key === "Backspace" ||
        e.key === "Enter"
      ) {
        return;
      }
      e.preventDefault();
    };

    const validFromTo = (index) => {
      if (main.currentRaffle.numbers[index].from && main.currentRaffle.numbers[index].to && Number(main.currentRaffle.numbers[index].from) > 0 && Number(main.currentRaffle.numbers[index].to) > 0 && Number(main.currentRaffle.numbers[index].from) <= Number(main.currentRaffle.numbers[index].to))
        return true;
      return false;
    };

    const validFromTo2 = () => {
      if (!main.currentRaffle.from || !main.currentRaffle.to || (Number(main.currentRaffle.from) === 0 && Number(main.currentRaffle.to) === 0 || Number(main.currentRaffle.from) <= Number(main.currentRaffle.to)))
        return true;
      return false;
    };

    return {
      main,
      fromToChange,
      clearFromTo,
      clearData,
      saveResult,
      clearResult,
      checkNumeric,
      checkRange,
      validFromTo,
      validFromTo2,
    };
  },
};
</script>